<template>
  <div class="page home-page">
    <div class="page__inner">
      <div class="container-padding">
        <BenefitsComponent />
      </div>
      <SectionComponent>
        <BannersComponent :banners="banners" />
        <CategoriesComponent :categories="categories_paginate" title="Популярные категории" />
      </SectionComponent>
      <SectionComponent v-if="lead_magnet_1 && lead_magnet_1.length">
        <ProductsComponent
          :products="lead_magnet_1"
          :banners="banner_healthy_products"
          :banners_wide="banner_under_healthy_products"
          title="Ключ к здоровому комфорту"
          row
        />
      </SectionComponent>
      <SectionComponent v-if="lead_magnet_2 && lead_magnet_2.length">
        <ProductsComponent
          :products="lead_magnet_2"
          :banners="banner_fashion"
          title="Стильно, модно и комфортно"
          reverse
          row
        />
      </SectionComponent>
      <SectionComponent>
        <OrthopedistBannerComponent />
        <SharesComponent :shares="shares" />
      </SectionComponent>
    </div>
  </div>
</template>

<script>
import HOME_PAGE from "@/graphql/pages/HomePage.graphql";
import BenefitsComponent from "components/BenefitsComponent.vue";
import CategoriesComponent from "components/CategoriesComponent.vue";
import BannersComponent from "components/BannersComponent.vue";
import ProductsComponent from "components/ProductsComponent.vue";
import SharesComponent from "components/SharesComponent.vue";
import SectionComponent from "components/SectionComponent.vue";
import OrthopedistBannerComponent from "components/OrthopedistBannerComponent.vue";

export default {
  name: "HomePage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: HOME_PAGE,
      })
      .then(({ data }) => {
        store.state.home_page.banners = data.banners;
        store.state.home_page.categories_paginate = data.categories_paginate?.data || [];
        store.state.home_page.lead_magnet_1 = data.lead_magnet_1?.data || [];
        store.state.home_page.lead_magnet_2 = data.lead_magnet_2?.data || [];
        store.state.home_page.banner_healthy_products = data.banner_healthy_products?.data || [];
        store.state.home_page.banner_under_healthy_products = data.banner_under_healthy_products?.data || [];
        store.state.home_page.banner_fashion = data.banner_fashion?.data || [];
        store.state.home_page.shares = data.shares || [];
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      })
      .catch(() => {});
  },
  computed: {
    banners() {
      return this.$store.state.home_page.banners;
    },
    categories_paginate() {
      return this.$store.state.home_page.categories_paginate || [];
    },
    lead_magnet_1() {
      return this.$store.state.home_page.lead_magnet_1;
    },
    lead_magnet_2() {
      return this.$store.state.home_page.lead_magnet_2;
    },
    banner_healthy_products() {
      return this.$store.state.home_page.banner_healthy_products;
    },
    banner_under_healthy_products() {
      return this.$store.state.home_page.banner_under_healthy_products;
    },
    banner_fashion() {
      return this.$store.state.home_page.banner_fashion;
    },
    shares() {
      return this.$store.state.home_page.shares;
    },
  },
  components: {
    OrthopedistBannerComponent,
    SectionComponent,
    SharesComponent,
    ProductsComponent,
    BannersComponent,
    CategoriesComponent,
    BenefitsComponent,
  },
  metaInfo: {
    title: "ОРТОСИТИ",
    // override the parent template and just use the above title only
    titleTemplate: null,
  },
};
</script>
